<template>
  <div>
    <div>
      <a-modal v-model="visible" title="Checkout" :footer="null">
        <a-spin tip="Processing order" :spinning="loading">
          <div
            class="order-quantity mb-4"
            v-for="service of services"
            :key="service._id"
          >
            <h3>{{ getServiceName(service) }}</h3>
          </div>

          <div class="additional-details-rentals">
            <div class="check-in-date">
              <label>{{ checkinText }}</label>
              <a-date-picker
                class="mb-2"
                :value="check_in_date"
                @change="checkinDateChangeHandler"
              />
            </div>

            <div class="check-out-date">
              <label>{{ checkoutText }}</label>
              <a-date-picker
                class="mb-2"
                :value="check_out_date"
                @change="checkoutDateChangeHandler"
              />
            </div>
          </div>

          <div class="payment-summary mb-4 mt-4" style="font-size: 1.5rem">
            <div>
              You are paying <strong>{{ totalAmount | formatCurrency }}</strong>
              <p class="text-warning text-sm" style="font-size: 13px">
                Please note that there will be additional service fee upon
                checkout.
              </p>
            </div>
          </div>

          <div class="mb-4 mt-4">
            <a-checkbox v-model="pay_with_cash">
              <span>Pay with cash</span>
            </a-checkbox>
          </div>

          <div class="payment-btn">
            <a-button
              class="pay-btn"
              type="primary"
              size="large"
              @click="processPayment"
              >Pay Now</a-button
            >
          </div>
        </a-spin>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { ORDER_SERVICE } from "@/store/actions";
import { mapActions } from "vuex";
import { cloneDeep } from "lodash";
import moment from "moment";

export default {
  props: {
    showModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    services: {
      type: Array,
      required: true,
      default: () => [],
    },
    entityId: {
      type: String,
      required: true,
      default: "",
    },
    entityType: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      quantities: {},
      check_in_date: moment(),
      check_out_date: moment().add(1, "days"),
      pickup_date: moment(),
      delivery_address: "",
      pay_with_cash: false,
    };
  },
  methods: {
    setDeliveryAddress(data) {
      const { street_number, route, locality, country } = data;
      this.delivery_address = `${street_number} ${route} ${locality} ${country}`;
    },
    ...mapActions("tourist", {
      orderService: ORDER_SERVICE,
    }),
    checkinDateChangeHandler(date) {
      const dateIsInThePast = moment().diff(date, "days") > 0;
      if (this.check_out_date.diff(date, "days") < 1 || dateIsInThePast) {
        this.$notification.warning({
          message: "Invalid Input",
          description: `Invalid ${this.checkinText} date selected`,
        });
      } else {
        this.check_in_date = date;
      }
    },
    checkoutDateChangeHandler(date) {
      if (date.diff(this.check_in_date, "days") < 1) {
        this.$notification.warning({
          message: "Invalid Input",
          description: `Invalid ${this.checkoutText} date selected`,
        });
      } else {
        this.check_out_date = date;
      }
    },
    refreshQuantities() {
      for (const service of this.services) {
        this.quantities[service._id] = 1;
      }
    },
    getServiceName(service) {
      const { title, vehicle_model } = service;
      const serviceName = title || vehicle_model;
      return serviceName;
    },
    prepareOrders() {
      const orders = [];
      for (const service of this.services) {
        orders.push({
          service_id: service._id,
          quantity: this.rentalQuantity,
          service_type: service.service_type,
        });
      }
      return orders;
    },
    getAdditionalDetails() {
      const isAccommodationService =
        this.entityType === "hotel_and_accommodations";
      const isTransportService = this.entityType === "transport";
      if (isAccommodationService || isTransportService) {
        if (!this.check_in_date || !this.check_out_date) {
          const accommodationError = "Checkin and checkout dates required";
          const transportError = "Pick up and return dates required";
          throw new Error(
            isAccommodationService ? accommodationError : transportError
          );
        }
        return isAccommodationService
          ? {
              check_in_date: this.check_in_date,
              check_out_date: this.check_out_date,
            }
          : {
              pickup_date: this.check_in_date,
              return_date: this.check_out_date,
            };
      }
      return {};
    },
    async processPayment() {
      if (this.services.length === 0) return;
      try {
        this.loading = true;
        const response = await this.orderService({
          platform: "web",
          entity_type: this.entityType,
          entity_id: this.entityId,
          orders: this.prepareOrders(),
          additional_details: this.getAdditionalDetails(),
          payment_mode: this.pay_with_cash ? "cash" : "electronic",
        });
        if (this.pay_with_cash) {
          this.$emit("cash-payment-processed", {
            payment_code: response.payment_code,
            transaction_id: response._id,
          });
          this.$emit("close-modal");
        } else {
          window.location.href = response.link;
          this.$notification.success({
            message: "Order successfully processed",
            description: "You will be redirected to a payment gateway shortly",
          });
        }
      } catch (error) {
        const message = error.message ? error.message : "Error making order.";
        console.log("Error", error);
        this.$notification.error({ message });
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    totalAmount() {
      let total = 0;
      for (const service of this.services) {
        total += service.price * (this.rentalQuantity || 0);
      }
      return total;
    },
    rentalQuantity() {
      //the quantity for rental is #days
      if (!this.check_in_date || !this.check_out_date) return null;
      return this.check_out_date.diff(this.check_in_date, "days");
    },
    checkinText() {
      return this.isTransportService ? "Pick up" : "Check In";
    },
    checkoutText() {
      return this.isTransportService ? "Return" : "Check Out";
    },
    isTransportService() {
      return this.entityType === "transport";
    },
  },
  watch: {
    showModal: {
      handler(show) {
        this.visible = show;
      },
      immediate: true,
    },
    visible(show) {
      if (!show) {
        this.$emit("close-modal", show);
      }
    },
    services: {
      handler() {
        this.refreshQuantities();
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.order-quantity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.pick-up-date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.additional-details-rentals {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.check-in-date,
.check-out-date {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.payment-summary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.payment-summary div {
  border-width: 1px;
  border-color: rgb(238, 231, 231);
  border-style: solid;
  padding: 8px;
  border-radius: 5px;
}

.payment-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2rem;
}

.pay-btn {
  padding-left: 30px;
  padding-right: 30px;
}
</style>
