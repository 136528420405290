<template>
  <div>
    <div>
      <a-modal v-model="visible" title="Checkout" :footer="null">
        <a-spin tip="Processing order" :spinning="loading">
          <div
            class="order-quantity mb-4"
            v-for="service of services"
            :key="service._id"
          >
            <p>
              How many <strong>{{ getServiceName(service) }}</strong> would you
              like
            </p>
            <a-input-number
              :value="quantities[service._id]"
              @change="quantityChangeHandler(service, $event)"
              :key="service.id"
              :min="1"
            />
          </div>
          <div
            class="additional-data-transport"
            v-if="entityType === 'transport'"
          >
            <div class="pick-up-date">
              <label>Pickup Date</label>
              <a-date-picker class="mb-2" v-model="pickup_date" />
            </div>
          </div>

          <div v-if="entityType === 'food_and_drinks'">
            <div style="margin-bottom: 1.2rem">
              <a-checkbox v-model="to_be_delivered">
                <span> I want it delivered</span>
              </a-checkbox>
            </div>
            <div v-if="to_be_delivered">
              <label>Where do you want it delivered</label>
              <vue-google-autocomplete
                id="map"
                classname="form-control"
                placeholder="Pinpoint your location"
                v-on:placechanged="setDeliveryAddress"
                :enable-geolocation="true"
                country="GH"
                :types="['establishment', 'address', 'geocode']"
              >
              </vue-google-autocomplete>
            </div>
          </div>

          <div class="payment-summary mb-4 mt-4" style="font-size: 1.5rem">
            <div>
              You are paying <strong>{{ totalAmount | formatCurrency }}</strong>
              <p class="text-warning text-sm" style="font-size: 13px">
                Please note that there will be additional service fee upon
                checkout.
              </p>
            </div>
          </div>

          <div class="mb-4 mt-4">
            <a-checkbox v-model="pay_with_cash">
              <span>Pay with cash</span>
            </a-checkbox>
          </div>

          <div class="payment-btn">
            <a-button
              class="pay-btn"
              type="primary"
              size="large"
              @click="processPayment"
              >Pay Now</a-button
            >
          </div>
        </a-spin>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { ORDER_SERVICE } from "@/store/actions";
import { mapActions } from "vuex";
import { cloneDeep } from "lodash";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import moment from "moment";

export default {
  components: { VueGoogleAutocomplete },
  props: {
    showModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    services: {
      type: Array,
      required: true,
      default: () => [],
    },
    entityId: {
      type: String,
      required: true,
      default: "",
    },
    entityType: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      quantities: {},
      check_in_date: moment(),
      check_out_date: moment().add(1, "days"),
      pickup_date: moment(),
      delivery_address: "",
      to_be_delivered: false,
      pay_with_cash: false,
    };
  },
  methods: {
    setDeliveryAddress(data) {
      const { street_number, route, locality, country } = data;
      this.delivery_address = `${street_number} ${route} ${locality} ${country}`;
    },
    ...mapActions("tourist", {
      orderService: ORDER_SERVICE,
    }),
    refreshQuantities() {
      for (const service of this.services) {
        this.quantities[service._id] = 1;
      }
    },
    quantityChangeHandler(service, newVal) {
      const quantities = cloneDeep(this.quantities);
      quantities[service._id] = newVal;
      this.quantities = quantities;
    },
    getServiceName(service) {
      const { title, vehicle_model } = service;
      const serviceName = title || vehicle_model;
      return serviceName;
    },
    prepareOrders() {
      const orders = [];
      for (const service of this.services) {
        orders.push({
          service_id: service._id,
          quantity: this.quantities[service._id],
          service_type: service.service_type,
        });
      }
      return orders;
    },
    getAdditionalDetails() {
      if (this.entityType === "hotel_and_accommodations") {
        if (!this.check_in_date || !this.check_out_date) {
          throw new Error("Checkin and checkout dates required");
        }
        return {
          check_in_date: this.check_in_date,
          check_out_date: this.check_out_date,
        };
      }
      if (this.entityType === "transport") {
        if (!this.pickup_date) throw new Error("Pickup date is required");
        return {
          pickup_date: this.pickup_date,
        };
      }
      if (this.entityType === "food_and_drinks") {
        if (!this.to_be_delivered) return {};
        if (!this.delivery_address)
          throw new Error("Delivery address is required");
        return {
          delivery_address: this.delivery_address,
        };
      }
      return {};
    },
    async processPayment() {
      if (this.services.length === 0) return;
      try {
        this.loading = true;

        const response = await this.orderService({
          platform: "web",
          entity_type: this.entityType,
          entity_id: this.entityId,
          orders: this.prepareOrders(),
          additional_details: this.getAdditionalDetails(),
          payment_mode: this.pay_with_cash ? "cash" : "electronic",
        });

        if (this.pay_with_cash) {
          this.$emit("cash-payment-processed", {
            payment_code: response.payment_code,
            transaction_id: response._id,
          });
          this.$emit("close-modal");
        } else {
          window.location.href = response.link;
          this.$notification.success({
            message: "Order successfully processed",
            description: "You will be redirected to a payment gateway shortly",
          });
        }
      } catch (error) {
        const message = error.message ? error.message : "Error making order.";
        console.log("Error", error);
        this.$notification.error({ message });
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    totalAmount() {
      let total = 0;
      for (const service of this.services) {
        total += service.price * (this.quantities[service._id] || 0);
      }
      return total;
    },
  },
  watch: {
    showModal: {
      handler(show) {
        this.visible = show;
      },
      immediate: true,
    },
    visible(show) {
      if (!show) {
        this.$emit("close-modal", show);
      }
    },
    services: {
      handler() {
        this.refreshQuantities();
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.order-quantity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.pick-up-date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.additional-details-hotels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.check-in-date,
.check-out-date {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.payment-summary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.payment-summary div {
  border-width: 1px;
  border-color: rgb(238, 231, 231);
  border-style: solid;
  padding: 8px;
  border-radius: 5px;
}

.payment-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2rem;
}

.pay-btn {
  padding-left: 30px;
  padding-right: 30px;
}
</style>
